define("discourse/plugins/discourse-assign/discourse/connectors/user-preferences-tracking-topics/notification-level-when-assigned", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _comboBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NotificationLevelWhenAssigned extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    constructor(owner, args) {
      super(...arguments);
      if (this.siteSettings.assign_enabled) {
        args.outletArgs.customAttrNames.push("notification_level_when_assigned");
      }
    }
    get notificationLevelsWhenAssigned() {
      // The order matches the "notification level when replying" user preference
      return [{
        name: (0, _discourseI18n.i18n)("user.notification_level_when_assigned.watch_topic"),
        value: "watch_topic"
      }, {
        name: (0, _discourseI18n.i18n)("user.notification_level_when_assigned.track_topic"),
        value: "track_topic"
      }, {
        name: (0, _discourseI18n.i18n)("user.notification_level_when_assigned.do_nothing"),
        value: "do_nothing"
      }];
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.siteSettings.assign_enabled}}
          <div
            class="controls controls-dropdown"
            data-setting-name="user-notification-level-when-assigned"
          >
            <label>{{i18n "user.notification_level_when_assigned.label"}}</label>
            <ComboBox
              @content={{this.notificationLevelsWhenAssigned}}
              @value={{@outletArgs.model.user_option.notification_level_when_assigned}}
              @valueProperty="value"
              @onChange={{action
                (mut @outletArgs.model.user_option.notification_level_when_assigned)
              }}
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "2zogWXHa",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"assign_enabled\"]],[[[1,\"      \"],[10,0],[14,0,\"controls controls-dropdown\"],[14,\"data-setting-name\",\"user-notification-level-when-assigned\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,[28,[32,0],[\"user.notification_level_when_assigned.label\"],null]],[13],[1,\"\\n        \"],[8,[32,1],null,[[\"@content\",\"@value\",\"@valueProperty\",\"@onChange\"],[[30,0,[\"notificationLevelsWhenAssigned\"]],[30,1,[\"model\",\"user_option\",\"notification_level_when_assigned\"]],\"value\",[28,[31,1],[[30,0],[28,[31,2],[[30,1,[\"model\",\"user_option\",\"notification_level_when_assigned\"]]],null]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\",\"action\",\"mut\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/connectors/user-preferences-tracking-topics/notification-level-when-assigned.js",
      "scope": () => [_discourseI18n.i18n, _comboBox.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = NotificationLevelWhenAssigned;
});